import { useEffect } from "react";
import { motion } from "framer-motion";

/**
 * Represents the Loader component.
 * Displays an animated loader with SVG icons.
 *
 * @component
 * @param {function} setShowLoader - A function to set whether the loader should be displayed.
 */

const Loader = ({ setShowLoader }) => {
  useEffect(() => {
    // Automatically hide the loader after a delay
    setTimeout(() => {
      setShowLoader(false);
    }, 2800);
  }, [setShowLoader]);

  // Animation variants for the SVG icons
  const iconVariant1 = {
    hidden: {
      pathLength: 0,
      fill: "rgb(19, 26, 34)",
    },
    visible: {
      pathLength: 5,
      fill: "rgb(243, 243, 243)",
      stroke: "rgb(243, 243, 243)",
      strokeWidth: 3,
      scale: 0,
      transition: {
        default: { duration: 3, ease: "easeIn" },
        fill: { duration: 3, ease: [1, 0, 0.8, 1] },
        stroke: { duration: 3, ease: [1, 0, 0.8, 1] },
        scale: { duration: 3, ease: [1, 1, 0.5, 0] },
      },
    },
  };

  const iconVariant2 = {
    hidden: {
      pathLength: 0,
      fill: "rgb(19, 26, 34)",
    },
    visible: {
      pathLength: 5,
      fill: "rgb(72, 163, 198)",
      stroke: "rgb(72, 163, 198)",
      scale: 0,
      strokeWidth: 3,
      transition: {
        default: { duration: 3, ease: "easeIn" },
        fill: { duration: 3, ease: [1, 0, 0.8, 1] },
        stroke: { duration: 3, ease: [1, 0, 0.8, 1] },
        scale: { duration: 3, ease: [1, 1, 0.5, 0] },
      },
    },
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      {/* Animated SVG */}
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 1 920.55 921.26"
        style={{ width: "30vw", height: "30vw" }}
      >
        {/* First SVG path */}
        <motion.path
          d="M674.68 717.05V851.1C798.58 772.91 882.55 637.44 889.64 481.89C889.99 474.8 890.11 467.72 890.11 460.63C890.11 431.1 887.28 402.17 881.96 374.06C857.75 246.38 780.86 137.25 674.68 70.28V204.22C722.16 249.69 757 308.28 773.42 374.06H562.83V19.02C528.46 8.63 492.32 2.13 455 0V481.89V902C489.5 899.5 531 891.5 562.83 882V481.89H783.46C778.03 574.13 737.28 656.93 674.68 717.05Z"
          variants={iconVariant2}
          initial="hidden"
          animate="visible"
        />

        {/* Second SVG path */}
        <motion.path
          d="M394 883V894.312L382.774 892.924C337.836 887.37 319.485 882.739 298.563 874.858L292.088 872.419V865.5V606.263L217.892 498.621L75.4516 723.845L67.7006 736.101L59 724.5C35.3463 692.962 23.8587 666.748 17.0875 651.297C15.9042 648.596 14.8649 646.225 13.9299 644.211L11.6575 639.317L14.501 634.73L155.313 407.62L39.3716 245.825L35.7223 240.732L38.7126 235.227C55.681 203.987 72.204 182.862 91.6397 161.73L100.047 152.59L107.184 162.753L292.088 426.053V40.5V33.165L299.084 30.9618C329.088 21.5133 347.861 17.0926 383.278 14.5262L394 13.7491V24.5V883Z"
          variants={iconVariant1}
          initial="hidden"
          animate="visible"
        />
      </motion.svg>
    </div>
  );
};

export default Loader;
