import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";
import Button from "./Button";
import aboutMeImg from "../images/aboutme.jpg";

/**
 * Represents the About Me section.
 * Displays information about the user.
 *
 * @component
 * @param {string} name - The name of the user.
 */

const AboutMe = ({ name }) => {
  // Using react-intersection-observer to determine if the component is in view
  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  // Variants for staggered animations
  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  // Variants for paragraph animations
  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section className="about">
      <div className="aboutContainer container">
        <div className="row">
          <motion.div
            className="personalImage col-12 col-lg-6"
            ref={ref}
            initial={{ x: "-10vw", opacity: 0, scale: 0.5 }}
            animate={inView ? { x: 0, opacity: 1, scale: 1 } : { x: "0vw", opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            whileHover={{ scale: 1.05 }}
          >
            {/* Display the personal image */}
            <motion.img src={aboutMeImg} alt={name} />
          </motion.div>
          <div className="personalInfo col-12 col-lg-6">
            <motion.div className="contentContainer" variants={staggerVariants}>
              {/* Display greeting and job title with animation */}
              <motion.h5 variants={paragraphVariants}>I'm a Web & Mobile Developer | Team Leader.</motion.h5>

              {/* Display content description with animation */}
              <motion.div
                className="contentDescription"
                variants={staggerVariants}
                initial="initial"
                animate={inView ? "animate" : "initial"}
              >
                {/* Paragraphs with animation */}
                <motion.p variants={paragraphVariants}>
                  A skilled <span style={{ color: "var(--hl-color)" }}>Web and Mobile Developer/Team Lead</span> with a
                  track record in creating cross-platform, dynamic applications and websites.
                </motion.p>
                <br />
                <motion.p variants={paragraphVariants}>
                  Having worked in both <span style={{ color: "var(--hl-color)" }}>Front-End</span> and <span style={{ color: "var(--hl-color)" }}>Back-End</span> development.
                  Skilled in a variety of programming languages and frameworks,
                  I can create fluid, aesthetically pleasing web and mobile applications
                  that adapt to the changing needs of users.
                </motion.p>
                <br />
              </motion.div>

              {/* Button to view the portfolio */}
              <NavLink to="/portfolio">
                <Button name="View Portfolio" />
              </NavLink>
            </motion.div>
          </div>
        </div>
        <motion.div
          className="contentDescription subDescription"
          variants={staggerVariants}
          initial="initial"
          animate={inView ? "animate" : "initial"}
        >
          <motion.p variants={paragraphVariants}>
            Here I immerse myself in the richness of nature and the magic of travel,
            donning a cloak of inspiration. My journey takes me through dense forests
            and vast fields, where each step is a new discovery. Studying the intricacies of nature,
            I become a part of this amazing world, unraveling its mysteries and preserving its beauty.
          </motion.p>
          <motion.p variants={paragraphVariants}>
            Life is an exciting journey, where every moment is filled with the vibrant colors of nature.
            In moments of repose, I become a nature cinephile, watching films where the earth unfolds
            its story, inspiring me for new adventures and prompting contemplation about the greatness
            of this world.
          </motion.p>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutMe;
